/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/*
 Custom default button
 */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: .05rem solid #fff;
}

/*
 * Base structure
 */
html,
body {
  background-color: #333;
}

body {
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

.root-container {
  text-align: center !important;
  width: 100%;
}

/*
 * Header
 */
.masthead {
  background-color: #333;
  max-width: 50em;
}

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

/*
 * Pages
 */

.page {
  max-width: 50em;
  padding: 0 1rem;
}

.page H1 {
  margin-bottom: 2rem;
}

.page H3 {
  margin-bottom: 1rem;
}

.page IMG {
  max-width:570px; width:100%
}

.page pre {
  width: max-content;
  max-width: 100%;
  display: inline-block;
  color: #eee;
  background-color: #555;
  padding: 1rem;
  text-align: left;
}

.page .thumbnail {
  margin-bottom: 20px;
}

.page .thumbnail .caption {
  height: 80px;
}